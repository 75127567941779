import { Injectable } from '@angular/core';
import { FilterOptionsService } from '../filter-options.service';
import { MeLocalExtendedCategory } from '../../entities/me-local/category/me-local-extended-category';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { MeLocalExtendedCategoryResource } from '../../entities/me-local/category/me-local-extended-category-resource';
import { Observable, of } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { MeLocalExtendedCategoryFactory } from '../../factories/me-local/category/me-local-extended-category-factory';
import { MeLocalObjectSearchParameters } from '../../entities/me-local/object/me-local-object-search-parameters';

export interface Filter {
  label: string;
  options: FilterOption[];
}

export interface FilterOption {
  label: string;
  value: number;
}

export interface FilterCategories {
  [filterIndex: number]: number[];
}

@Injectable({
  providedIn: 'root'
})
export class MeLocalCategoryService extends FilterOptionsService<MeLocalExtendedCategory> {

  constructor(httpClient: HttpClient) {
    super(
      `${environment.apiGatewayUrl}v1/melocal/category`,
      httpClient,
    );
  }

  option(resource: MeLocalExtendedCategoryResource['data']): MeLocalExtendedCategory {
    return new MeLocalExtendedCategory(resource);
  }

  filters(rootID: number): Observable<Filter[]> {
    return this.options$.pipe(
      map(categories => {
        const filterIDs = new Set(
          categories
            .filter(category => category.isLeaf && category.rootId === rootID)
            .map(category => category.parentId)
        );

        return categories
          .filter(category => filterIDs.has(category.id))
          .map(filterCategory => ({
            label: filterCategory.title,
            options: categories
              .filter(category => category.parentId === filterCategory.id && category.isLeaf)
              .map(filterOptionCategory => ({
                label: filterOptionCategory.title,
                value: filterOptionCategory.id,
              }))
          }));
      }),
    );
  }

  filterCategories(filters: Filter[], searchParameters?: MeLocalObjectSearchParameters): FilterCategories {
    const categories = new Set(
      searchParameters?.categories
        ?.split(',')
        .map((id) => parseInt(id, 10))
        .filter((id) => !isNaN(id))
      || [],
    );

    const filterCategories = {};
    filters.forEach((filter, filterIndex) => {
      filterCategories[filterIndex] = [];
      for (const option of filter.options) {
        if (categories.has(option.value)) {
          filterCategories[filterIndex].push(option.value);
        }
      }
    });

    return filterCategories;
  }

}
