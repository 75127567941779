import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { MeLocalObjectService } from '../services/me-local/me-local-object.service';
import { fromPromise } from 'rxjs/internal-compatibility';
import { LoadingController } from '@ionic/angular';
import { Title } from '@angular/platform-browser';
import { LikeService } from '../services/like.service';
import { MeLocalObjectDetailPageData } from '../pages/me-local/me-local-object-detail/me-local-object-detail.page';
import { isMeLocalRouteData } from '../pages/me-local/me-local-route-data';

type ResolveData = MeLocalObjectDetailPageData['meLocalObjectDetailPageData'];

@Injectable({
  providedIn: 'root'
})
export class MeLocalObjectResolverService implements Resolve<undefined | ResolveData> {

  private loadingElement?: HTMLIonLoadingElement;

  constructor(
    private meLocalObjectService: MeLocalObjectService,
    private router: Router,
    private loadingController: LoadingController,
    private titleService: Title,
    private likeService: LikeService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    return fromPromise((async () => {
      this.loadingElement = await this.loadingController.create({
        spinner: 'bubbles',
      });
      await this.loadingElement.present();

      let resolveData: undefined | ResolveData;

      try {
        const objectID: number = +(route.paramMap.get('id') || 0);

        let categoryRootID: undefined | number;
        if (isMeLocalRouteData(route.data)) {
          categoryRootID = route.data.meLocal.categoryRootID;
        }

        const meLocalObject = await this.meLocalObjectService.getObject(
          objectID,
          categoryRootID,
        ).toPromise();

        if (meLocalObject) {
          const like = await this.likeService.like(meLocalObject);

          this.titleService.setTitle(meLocalObject.title);

          resolveData = [meLocalObject, like];
        }
      }
      finally {
        await this.loadingElement.dismiss();

        if (!resolveData) {
          await this.router.navigate(['/not-found'], { replaceUrl: true });
        }
      }

      return resolveData;
    })());
  }

}
