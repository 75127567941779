import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ResourceCollection } from '../../entities/resource-collection';
import { environment } from '../../../environments/environment';
import { ListItemService } from '../list-item.service';
import { LoggerService } from '../logger.service';
import { LikeService } from '../like.service';
import { MeLocalObject } from '../../entities/me-local/object/me-local-object';
import { MeLocalObjectResource } from '../../entities/me-local/object/me-local-object-resource';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MeLocalObjectSearchParameters } from '../../entities/me-local/object/me-local-object-search-parameters';
import { Location } from '@angular/common';
import { ActivatedRoute, ActivatedRouteSnapshot, Data } from '@angular/router';
import { isMeLocalRouteData } from '../../pages/me-local/me-local-route-data';
import { MeLocalCategoryService } from './me-local-category.service';
import { withoutUndefined } from '../../support/helpers';

@Injectable({
  providedIn: 'root'
})
export class MeLocalObjectService extends ListItemService<MeLocalObject, MeLocalObjectSearchParameters> {

  categoryRootID?: number;

  private categoryService: MeLocalCategoryService;

  private readonly defaultSearchParameters: MeLocalObjectSearchParameters;

  constructor(
    http: HttpClient,
    logger: LoggerService,
    likeService: LikeService,
    location: Location,
    activatedRoute: ActivatedRoute,
    categoryService: MeLocalCategoryService,
  ) {
    let routeData: Data = activatedRoute.snapshot.data;
    while (activatedRoute.firstChild) {
      activatedRoute = activatedRoute.firstChild;
      routeData = activatedRoute.snapshot.data;
    }
    const meLocalRouteData = isMeLocalRouteData(routeData) ? routeData : null;

    const defaultSearchParameters = {
      page: 1,
      perPage: 20,
      categories: meLocalRouteData
        ? `${meLocalRouteData.meLocal.categoryRootID}`
        : '',
    };

    const sanitizedQueryParameters = MeLocalObjectService.sanitizedQueryParameters(activatedRoute.snapshot);
    const initialSearchParameters: MeLocalObjectSearchParameters = {
      ...defaultSearchParameters,
      ...withoutUndefined(sanitizedQueryParameters),
    };

    super(
      `${environment.apiGatewayUrl}v1/melocal/object`,
      initialSearchParameters,
      'me-local',
      http,
      logger,
      likeService,
      location,
    );

    this.categoryService = categoryService;
    this.categoryRootID = meLocalRouteData?.meLocal.categoryRootID;
    this.defaultSearchParameters = defaultSearchParameters;

    if (!this.categoryService.options$.value.length) {
      this.categoryService.load().subscribe();
    }
  }

  private static sanitizedQueryParameters(
    routeSnapshot: ActivatedRouteSnapshot,
  ): Omit<MeLocalObjectSearchParameters, 'page' | 'perPage'> {
    const queryParameters = { ...routeSnapshot.queryParams };
    Object.keys(queryParameters).forEach(key => queryParameters[key] = decodeURIComponent(queryParameters[key]));

    return {
      ids: queryParameters.ids,
      query: queryParameters.query,
      categories: MeLocalObjectService.sanitizedIDs(queryParameters.categories),
      locations: MeLocalObjectService.sanitizedIDs(queryParameters.locations),
    };
  }

  /**
   * Returns unique, comma separated, numeric IDs from a given string
   */
  private static sanitizedIDs(ids?: string): undefined | string {
    return Array.from(
      new Set(ids?.split(',').map((id) => parseInt(id, 10)).filter((id) => !isNaN(id)))
    ).join(',') || undefined;
  }

  resetFilters() {
    const nextParams: MeLocalObjectSearchParameters = { ...this.defaultSearchParameters };
    const [query, ids] = [this.searchParameters$.value.query, this.searchParameters$.value.ids];
    if (query) {
      nextParams.query = query;
    }
    if (ids) {
      nextParams.ids = ids;
    }
    this.searchParameters$.next(nextParams);
  }

  get numberOfActiveFilters$(): Observable<number> {
    return this.searchParameters$.pipe(
      switchMap(params => {
        if (params.categories === `${this.categoryRootID}` || !this.categoryRootID) {
          return of({
            params,
            pendingNumberOfActiveFilters: 0,
          });
        }

        return this.categoryService.filters(this.categoryRootID).pipe(
          map(filters => {
            const filterCategories = this.categoryService.filterCategories(filters, params);
            return {
              params,
              pendingNumberOfActiveFilters: Object.values(filterCategories)
                .filter((categories) => Boolean(categories.length))
                .length
            };
          }),
        );
      }),
      map(({ params, pendingNumberOfActiveFilters }) => {
        if (typeof params.locations === 'string' && params.locations.length > 0) {
          return pendingNumberOfActiveFilters + 1;
        }
        return pendingNumberOfActiveFilters;
      }),
    );
  }

  getObject(id: number, categoryRootID?: number): Observable<null | MeLocalObject> {
    return this.http.get<MeLocalObjectResource>(`${this.apiURL}/${id}`).pipe(
      map(objectResource => new MeLocalObject(objectResource.data, categoryRootID)),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 404) {
          return of(null);
        }
        return throwError(error);
      }),
    );
  }

  protected id(item: MeLocalObject): string {
    return `${item.id}`;
  }

  protected items(
    resources: ResourceCollection<MeLocalObjectResource>,
    searchParameters
  ): MeLocalObject[] {
    let categoryRootID = this.categoryRootID;
    if (!categoryRootID) {
      const firstSearchCategory = searchParameters.categories?.split(',')[0];
      if (firstSearchCategory) { categoryRootID = +firstSearchCategory; }
    }

    return resources.data.map(resource => new MeLocalObject(resource, categoryRootID));
  }

}
