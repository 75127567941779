import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceCollection } from '../entities/resource-collection';
import { EventDate } from '../entities/msuevent/date/event-date';
import { EventDateResource } from '../entities/msuevent/date/event-date-resource';
import { environment } from '../../environments/environment';
import { ListItemService } from './list-item.service';
import { EventDateSearchParameters } from '../entities/msuevent/date/event-date-search-parameters';
import { LoggerService } from './logger.service';
import { LikeService } from './like.service';
import { Location } from '@angular/common';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { withoutUndefined } from '../support/helpers';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventDateService extends ListItemService<EventDate, EventDateSearchParameters> {

  private static defaultSearchParameters: EventDateSearchParameters = { page: 1, perPage: 20 };

  constructor(
    http: HttpClient,
    logger: LoggerService,
    likeService: LikeService,
    location: Location,
    activatedRoute: ActivatedRoute,
  ) {
    const sanitizedQueryParameters = EventDateService.sanitizedQueryParameters(activatedRoute.snapshot);
    const initialSearchParameters: EventDateSearchParameters = {
      ...EventDateService.defaultSearchParameters,
      ...withoutUndefined(sanitizedQueryParameters),
    };

    super(
      `${environment.apiGatewayUrl}v1/msuevent/date`,
      initialSearchParameters,
      'msu-event',
      http,
      logger,
      likeService,
      location,
    );
  }

  private static sanitizedQueryParameters(
    routeSnapshot: ActivatedRouteSnapshot
  ): Omit<EventDateSearchParameters, 'page' | 'perPage'> {
    const queryParameters = { ...routeSnapshot.queryParams };
    Object.keys(queryParameters).forEach(key => queryParameters[key] = decodeURIComponent(queryParameters[key]));

    return {
      ids: queryParameters.ids,
      query: queryParameters.query,
      categories: queryParameters.categories,
      features: queryParameters.features,
      startDate: queryParameters.startDate,
      endDate: queryParameters.endDate,
    };
  }

  resetFilters() {
    const nextParams: EventDateSearchParameters = { ...EventDateService.defaultSearchParameters };
    const [query, ids] = [this.searchParameters$.value.query, this.searchParameters$.value.ids];
    if (query) {
      nextParams.query = query;
    }
    if (ids) {
      nextParams.ids = ids;
    }
    this.searchParameters$.next(nextParams);
  }

  get numberOfActiveFilters$(): Observable<number> {
    return this.searchParameters$.pipe(
      map(searchParameters => {
        const params: Partial<EventDateSearchParameters> = { ...searchParameters };
        delete params.page; // don't count the `page` param
        delete params.perPage; // don't count the `perPage` param
        delete params.query; // don't count the query
        delete params.ids; // don't count IDs

        return Object.values(params)
          .filter((value) => { // don't count empty strings
            if (typeof value !== 'string') {
              return true;
            }
            return value.trim() !== '';
          })
          .length;
      }),
    );
  }

  protected id(item: EventDate): string {
    return item.id;
  }

  protected items(resources: ResourceCollection<EventDateResource>): EventDate[] {
    return resources.data.map(resource => new EventDate(resource));
  }

}
