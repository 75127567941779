import { MeLocalCategory } from './me-local-category';
import { MeLocalExtendedCategoryResource } from './me-local-extended-category-resource';

export class MeLocalExtendedCategory extends MeLocalCategory {

  readonly parentId: null | number;
  readonly rootId: number;
  readonly isLeaf: boolean;

  constructor(resource: MeLocalExtendedCategoryResource['data']) {
    super(resource);
    this.parentId = resource.parentId;
    this.rootId = resource.rootId;
    this.isLeaf = resource.isLeaf;
  }

}
